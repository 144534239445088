import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlNameService {
  protected lettersForReplace: object = {
    À: 'A', Á: 'A', Â: 'A', Ã: 'A', Ä: 'A', Å: 'A', Ă: 'A', Ā: 'A', Ą: 'A', Æ: 'A', Ǽ: 'A',
    à: 'a', á: 'a', â: 'a', ã: 'a', ä: 'a', å: 'a', ă: 'a', ā: 'a', ą: 'a', æ: 'a', ǽ: 'a',
    Þ: 'B', þ: 'b', ß: 'Ss', Ç: 'C', Č: 'C', Ć: 'C', Ĉ: 'C', Ċ: 'C', ç: 'c', č: 'c', ć: 'c',
    ĉ: 'c', ċ: 'c', Đ: 'Dj', Ď: 'D', đ: 'dj', ď: 'd', È: 'E', É: 'E', Ê: 'E', Ë: 'E', Ĕ: 'E',
    Ē: 'E', Ę: 'E', Ė: 'E', è: 'e', é: 'e', ê: 'e', ë: 'e', ĕ: 'e', ē: 'e', ę: 'e', ė: 'e',
    Ĝ: 'G', Ğ: 'G', Ġ: 'G', Ģ: 'G', ĝ: 'g', ğ: 'g', ġ: 'g', ģ: 'g', Ĥ: 'H', Ħ: 'H', ĥ: 'h',
    ħ: 'h', Ì: 'I', Í: 'I', Î: 'I', Ï: 'I', İ: 'I', Ĩ: 'I', Ī: 'I', Ĭ: 'I', Į: 'I', ì: 'i',
    í: 'i', î: 'i', ï: 'i', į: 'i', ĩ: 'i', ī: 'i', ĭ: 'i', ı: 'i', Ĵ: 'J', ĵ: 'j', Ķ: 'K',
    ķ: 'k', ĸ: 'k', Ĺ: 'L', Ļ: 'L', Ľ: 'L', Ŀ: 'L', Ł: 'L', ĺ: 'l', ļ: 'l', ľ: 'l', ŀ: 'l',
    ł: 'l', Ñ: 'N', Ń: 'N', Ň: 'N', Ņ: 'N', Ŋ: 'N', ñ: 'n', ń: 'n', ň: 'n', ņ: 'n', ŋ: 'n',
    ŉ: 'n', Ò: 'O', Ó: 'O', Ô: 'O', Õ: 'O', Ö: 'O', Ø: 'O', Ō: 'O', Ŏ: 'O', Ő: 'O', Œ: 'O',
    ò: 'o', ó: 'o', ô: 'o', õ: 'o', ö: 'o', ø: 'o', ō: 'o', ŏ: 'o', ő: 'o', œ: 'o', ð: 'o',
    Ŕ: 'R', Ř: 'R', ŕ: 'r', ř: 'r', ŗ: 'r', Š: 'S', Ŝ: 'S', Ś: 'S', Ş: 'S', š: 's', ŝ: 's',
    ś: 's', ş: 's', Ŧ: 'T', Ţ: 'T', Ť: 'T', ŧ: 't', ţ: 't', ť: 't', Ù: 'U', Ú: 'U', Û: 'U',
    Ü: 'U', Ũ: 'U', Ū: 'U', Ŭ: 'U', Ů: 'U', Ű: 'U', Ų: 'U', ù: 'u', ú: 'u', û: 'u', ü: 'u',
    ũ: 'u', ū: 'u', ŭ: 'u', ů: 'u', ű: 'u', ų: 'u', Ŵ: 'W', Ẁ: 'W', Ẃ: 'W', Ẅ: 'W', ŵ: 'w',
    ẁ: 'w', ẃ: 'w', ẅ: 'w', Ý: 'Y', Ÿ: 'Y', Ŷ: 'Y', ý: 'y', ÿ: 'y', ŷ: 'y', Ž: 'Z', Ź: 'Z',
    Ż: 'Z', ž: 'z', ź: 'z', ż: 'z', ' ': '-',
  };

  getFriendlyUrl(nonFriendlyUrl: string): string {
    if (!nonFriendlyUrl) {
      return '';
    }

    let friendlyUrl: string;
    friendlyUrl = this.replaceAll(nonFriendlyUrl, '/', '');
    friendlyUrl = friendlyUrl.trim();

    Object.entries(this.lettersForReplace).forEach(([search, replace]) => {
      friendlyUrl = this.replaceAll(friendlyUrl, search, replace);
    });

    friendlyUrl = this.removeSpecialSymbolsByRegex(friendlyUrl);
    friendlyUrl = friendlyUrl.toLowerCase();
    friendlyUrl = this.replaceAll(friendlyUrl, '.', '');
    friendlyUrl = this.replaceAll(friendlyUrl, '--', '-');

    return friendlyUrl;
  }

  replaceAll(str?: string, replace?: string, by?: string): string {
    if (!str || !replace || !by) {
      return '';
    }
    while (str.includes(replace)) {
      str = str.replace(replace, by);
    }
    return str;
  }

  removeSpecialSymbolsByRegex(url: string) {
    const regex = '/[^a-zA-Z0-9\\/\\-_.]/';
    return this.replaceAll(url, regex, '');
  }
}
